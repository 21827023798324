import AOS from "aos";
import '../../assets/scripts/removeTagsFromHeaders.jsx';
import '../../assets/scripts/labelForTargetBlank.jsx';
import '../../components/navigation/navigation.jsx';
import '../../components/cookie-bar/cookie-bar.jsx';
import {getPosts} from '../../assets/scripts/getPosts.jsx';
import 'particles.js';
import Plyr from 'plyr';
import './front.scss';

try {
  AOS.init({
    duration: 300,
  });

  // getPosts('event', 3, {'datetime-from': todayDateFormatted()}, null, '.promo-list');
  getPosts('note', 3, null, null, '.notes-list');
  getPosts('entry', 3, null, 'publish_date', '.entries-list', null, null, null, 'DESC');
} catch (e) {}

try {
  const origin = window.location.origin;
  const isDevelopment = origin.includes('localhost') || origin.includes('udostepnij-sie21.usermd.net');

  particlesJS.load('header-particles', `${origin}${isDevelopment ? '/fundation' : ''}/wp-content/themes/fundationTheme/pages/front/particles.json`);
} catch (e) {
  console.log(e);
}

try {
  const btnAnim = document.querySelector('.header-section .btn-anim');
  const headerCanvas = document.querySelector('.header-section #header-particles');

  btnAnim.addEventListener('click', () => {
    headerCanvas.classList.toggle('d-none');
    btnAnim.innerHTML = headerCanvas.classList.contains('d-none') ? 'Włącz animację' : 'Wyłącz animację';
  })

} catch (e) {
  console.log(e);
}

try {
  const i18n = {
    restart: 'Restart',
    rewind: 'Przewiń {seektime}s',
    play: 'Odtwórz',
    pause: 'Pauza',
    fastForward: 'Do przodu {seektime}s',
    seek: 'szukaj',
    seekLabel: '{currentTime} z {duration}',
    played: 'Odtworzone',
    buffered: 'Buforuję',
    currentTime: 'Czas',
    duration: 'Trwanie',
    volume: 'Glośność',
    mute: 'Wycisz',
    unmute: 'wyłącz wyciszenie',
    enableCaptions: 'Włącz napisy',
    disableCaptions: 'Wyłącz napisy',
    download: 'Pobierz',
    enterFullscreen: 'Włącz pełny ekran',
    exitFullscreen: 'Wyłącz pełny ekran',
    frameTitle: 'Player for {title}',
    captions: 'Napisy',
    settings: 'Ustawienia',
    pip: 'Odtwórz obraz w obrazie',
    menuBack: 'Do menu',
    speed: 'Szybkość',
    normal: 'Normalny',
    quality: 'Jakośc',
    loop: 'Pętla',
    start: 'Start',
    end: 'Koniec',
    all: 'Wszystko',
    reset: 'Reset',
    disabled: 'Zablkowany',
    enabled: 'Odblokowany',
    advertisement: 'Ad',
  }

  const videoAd = new Plyr('.video-section .player-1', {
    i18n
  });

  const videoPjm = new Plyr('.video-section .player-2', {
    i18n
  });
  videoPjm.on('play',() => {
    videoAd.stop();
  })

  videoAd.on('play',() => {
    videoPjm.stop();
  })
} catch (e) {
  console.log(e);
}
