import 'bootstrap/dist/js/bootstrap.min.js';
import './toggle-contrast/toggle-contrast.jsx';

try {
  const skipLinksWrapper = document.querySelector('.skip-links');
  const skipLinks = skipLinksWrapper.querySelectorAll('a');

  skipLinks.forEach(elem => elem.addEventListener('focus', () => {
    skipLinksWrapper.classList.remove('opacity-0')
  }))
} catch (e) {
  console.log(e);
}
