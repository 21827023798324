try {
  const blanks = document.querySelectorAll('a[target="_blank"]');

  blanks.forEach(elem => {
    const prevAttribute = elem.getAttribute('aria-label');

    if(!prevAttribute) {
      elem.setAttribute('aria-label', `${elem.textContent} - link otwiera się w nowej karcie`);
    }
  });
} catch (e) {
  console.log(e)
}
