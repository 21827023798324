try {
  const btnToggleContrast = document.querySelector('.btn-toggle-contrast');
  const body = document.querySelector('body');

  const addContrast = () => {
    body.classList.add('contrast');
    btnToggleContrast.setAttribute('aria-checked', true);
  }

  const removeContrast = () => {
    body.classList.remove('contrast');
    btnToggleContrast.setAttribute('aria-checked', false);
  }

  if(localStorage.getItem('contrast') === 'true') {
    addContrast();
  } else {
    removeContrast();
  }

  btnToggleContrast.addEventListener('click', () => {
    if(localStorage.getItem('contrast') === 'true') {
      localStorage.setItem('contrast', 'false');
      removeContrast();
    } else {
      localStorage.setItem('contrast', 'true');
      addContrast();
    }
  });
} catch (e) {
  console.log(e);
}
