import $ from "jquery";


  export const getPosts = (postType, postsPerPage, filters, orderby, selector, postNotIn, category, authorId, order) => {
    const postsList = document.querySelector(selector || '.posts-list');
    const adminAjax = document.body.getAttribute('data-admin-ajax');

    console.log(order);

    const params = {
      'action': 'load_posts',
      'security': '<?php echo wp_create_nonce("load_more"); ?>',
      filters,
      postType,
      order,
      orderby,
      postsPerPage,
      postNotIn,
      category,
      authorId
    };

    console.log(params);
    try {
      return $.post(adminAjax, params, response => {

        if (response !== '') {
          postsList.innerHTML = response;
        } else {
          postsList.innerHTML = '<div class="col-12 text-danger font-size-16 font-weight-bold">Brak wyników</div>';
        }
      });

    } catch (e) {
      console.log(e);
    }
  }



